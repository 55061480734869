import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './lang/en.json'
import zh_cn from './lang/zh-cn.json'
import es from './lang/es.json'
import ar from './lang/ar.json'
import tr from './lang/tr.json'
import appConfig from 'configs/app.config'

const resources = {
    tr: {
        translation: tr,
    },
    en: {
        translation: en,
    },
    zhCn: {
        translation: zh_cn,
    },
    es: {
        translation: es,
    },
    ar: {
        translation: ar,
    },
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: appConfig.locale,
    lng: appConfig.locale,
    interpolation: {
        escapeValue: false,
    },
})

export const dateLocales = {
    tr: () => import('dayjs/locale/tr'),
    en: () => import('dayjs/locale/en'),
}

export default i18n
