const appConfig = {
    //apiPrefix: 'https://rentacarsoft-api.azurewebsites.net',
    //apiPrefix: 'https://localhost:7011',
    apiPrefix: 'https://api.rentacarsoft.com',

    authenticatedEntryPath: '/app/project/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'tr',
    enableMock: false,
}

export default appConfig